import { mapActions, mapGetters, mapMutations } from "vuex"
import moment from "moment"

export default {
  name: 'ReportExport',
  props: {
    fn: {
      type: Function,
      required: true
    },
    title: {
      type: String,
    },
    formats: {
      type: Array,
      default: () => {
        return []
      },
    }
  },
  data() {
    return {
      availableFormats: [
        {
          format: 'csv',
          buttonStyle: '',
          loading: false
        },
        {
          format: 'xlsx',
          buttonStyle: 'outlined',
          loading: false
        },
      ]
    }
  },
  computed: {
    ...mapGetters({
      exportStatus: 'statistics/exportStatus'
    }),
    usedFormats() {
      return this.availableFormats.filter(item => !this.formats.length || this.formats.includes(item.format))
    }
  },
  methods: {
    ...mapMutations({
      setExportStatus: 'statistics/SET_EXPORT_STATUS'
    }),
    ...mapActions({
      checkExportStatus: 'statistics/GET_EXPORT_STATUS'
    }),
    async makeReport(format) {
      var item = this.availableFormats.find(item => item.format === format)

      if (!item) {
        return;
      }

      item.loading = true;

      try {
        var token = await this.fn(item.format)
      } catch (e) {
        console.log(e);

        item.loading = false;

        return
      }

      let interval = setInterval(() => {
        try {
          this.checkExportStatus({ export: token })
            .then(() => {
              if (this.exportStatus !== 'completed') {
                return;
              }

              let url = `${process.env.VUE_APP_ROOT_STATISTIC}exports/${token}/download`;

              window.open(url, '_blank')

              clearInterval(interval);
              item.loading = false;
            })
            .catch(error => {
              console.log(error);

              clearInterval(interval);
              item.loading = false;
            });
        } catch (e) {
          console.log(e);

          clearInterval(interval);
          item.loading = false;
        }
      }, 3500)
    }
  }
}
