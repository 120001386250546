import { mapActions, mapGetters } from 'vuex'
import packageColors from '../../helpers/package-colors.js'
import ReportExport from '../../features/report-export/index.vue'
import reportMixin from '@/mixins/reportMixin'

export default {
  components: {
    ReportExport
  },
  mixins: [reportMixin],
  computed: {
    headers () {
      return [
        {
          text: 'Name',
          value: 'name'
        },
        {
          text: 'Package',
          value: 'package'
        },
        {
          text: 'Points',
          value: 'points'
        },
        {
          text: 'Progress',
          value: 'progress'
        },
        {
          text: 'Current progress',
          value: 'material'
        },
        {
          text: 'Actions',
          sortable: false,
          value: 'actions'
        }
      ]
    },
    colors () {
      return packageColors.colors
    },
    ...mapGetters({
      graduateWorkData: 'statistics/graduateWorkData'
    }),
    reportFn () {
      return this.createReportFn({
        url: `${process.env.VUE_APP_ROOT_STATISTIC}courses/${this.$route.params.courseId}/graduate-work`,
        method: 'get'
      })
    }
  },
  created () {
    this.fetchGraduateDetails({
      courseId: this.$route.params.courseId
    })
  },
  methods: {
    ...mapActions({
      fetchGraduateDetails: 'statistics/GET_STATISTICS_GRADUATE_WORK_DETAILS'
    })
  }
}
