export default {
  colors: [
    {
      color: '#D81B60',
      sort: 1
    },
    {
      color: '#8E24AA',
      sort: 2
    },
    {
      color: '#1565C0',
      sort: 3
    },
    {
      color: '#00897B',
      sort: 4
    },
    {
      color: '#FB8C00',
      sort: 5
    },
    {
      color: '#546E7A',
      sort: 6
    },
    {
      color: '#3949AB',
      sort: 7
    },
    {
      color: '#00ACC1',
      sort: 8
    },
    {
      color: '#3E2723',
      sort: 9
    },
    {
      color: '#212121',
      sort: 10
    },
    {
      color: '#263238',
      sort: 11
    },
    {
      color: '#F57F17',
      sort: 12
    },
    {
      color: '#827717',
      sort: 13
    },
    {
      color: '#1B5E20',
      sort: 14
    },
    {
      color: '#0277BD',
      sort: 15
    },
    {
      color: '#311B92',
      sort: 16
    },
    {
      color: '#880E4F',
      sort: 17
    },
    {
      color: '#6D4C41',
      sort: 18
    }
  ]
}
