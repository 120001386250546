import { $http } from '@/utils/https'

function getPayload (axiosConfig, format) {
  if (axiosConfig.method === 'get') {
    return {
      params: {
        export: 1,
        format
      }
    }
  } else {
    return {
      data: {
        export: 1,
        format
      }
    }
  }
}

export default {
  methods: {
    createReportFn (axiosConfig) {
      return async function (format) {
        const payload = getPayload(axiosConfig, format)
        const { data } = await $http.request({
          ...$http.defaults,
          ...axiosConfig,
          ...payload
        })
        return data.data.report
      }
    }
  }
}
